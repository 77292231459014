import React from 'react';
import Absec1 from './About/Absec1';
import Absec2 from './About/Absec2';


const About = () => {
  return (
    <>
      <Absec1 />
      <Absec2 />
    </>
  )
}
export default About ;